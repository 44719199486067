<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="openNew" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="currencies" class="p-datatable-responsive-demo" v-model:selection="selectedCurrencies" dataKey="id" 
					:paginator="true" :rows="10" :filters="filters" :loading="loading1" :rowsPerPageOptions="[5,10,25]"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} currencies">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage Currencies</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="USD" header="USD" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">USD</span>
							{{slotProps.data.usd}}
						</template>
					</Column>
					<Column field="EUR" header="EUR" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">EUR</span>
							{{ slotProps.data.eur }}
						</template>
					</Column>
					<Column field="SUM" header="SUM" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">SUM</span>
							{{ slotProps.data.sum }}
						</template>
					</Column>
						
				</DataTable>

				<Dialog v-model:visible="currencyDialog" :style="{width: '800px'}" header="Blog Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="title">USD</label>
							<InputText id="title" v-model.trim="currency.usd" required="true" autofocus :class="{'p-invalid': submitted && !currency.usd}" />
							<small class="p-invalid" v-if="submitted && !currency.usd">USD is required.</small>
						</div>
						<div class="p-field p-col">
							<label for="title">EUR</label>
							<InputText id="title" v-model.trim="currency.eur" required="true" autofocus :class="{'p-invalid': submitted && !currency.eur}" />
							<small class="p-invalid" v-if="submitted && !currency.eur">EUR is required.</small>
						</div>
						<div class="p-field p-col">
							<label for="title">SUM</label>
							<InputText id="title" v-model.trim="currency.sum" required="true" autofocus :class="{'p-invalid': submitted && !currency.sum}" />
							<small class="p-invalid" v-if="submitted && !currency.sum">SUM is required.</small>
						</div>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveCurrency" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import ProductsService from '../service/ProductsService';

export default {
	data() {
		return {
			currencies: null,
			currencyDialog: false,
			currency: {},
			selectedCurrencies: null,
			filters: {},
			submitted: false,
			statusOptions:[{name: 'Published', is_published:'1'}, {name: 'Unpublished', is_published:'0'}],
			statusOption:{},
			loading1: true
		}
	},
	currencyService: null,
	created() {
		this.currencyService = new ProductsService();
	},
	mounted() {
		this.onMounted();
	},
	methods: {
		onMounted() {
			this.currencyService.getCurrencyAll().then(data => {
				console.log(data);
				if (data.data.isLogged) {
					this.currencies = data.data.fetch_data
					this.loading1 = false
				} else {
					this.$router.push('Login')
				}
			});
		},
		openNew() {
			this.currency = {};
			this.statusOption = this.statusOptions[1]
			this.submitted = false;
			this.currencyDialog = true;
		},
		hideDialog() {
			this.currencyDialog = false;
			this.submitted = false;
        },
		saveCurrency() {
			this.submitted = true;
			this.currencyService.insertCurrency(this.currency)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.currencyDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		

			this.currencyDialog = false;
			this.currency = {};
		},
		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>

import axios from 'axios'

import FormData from 'form-data'
axios.defaults.withCredentials = true;

export default class ProductsService {

  getProductsAll() {
    return axios.get('https://advantex.uz/product/getProductList').then(res => res.data);
  }

  insertProduct(newProduct) {
    var data = new FormData();
    data.append('product_name', newProduct.product_name);
    data.append('product_code', newProduct.product_code);
    data.append('product_width', newProduct.product_width);
    data.append('product_length', newProduct.product_length);
    data.append('product_thickness', newProduct.product_thickness);
    data.append('product_cost', newProduct.product_cost);
    data.append('price_sum', newProduct.price_sum);
    data.append('product_diameter', newProduct.product_diameter);
    data.append('product_profile', newProduct.product_profile);
    data.append('price_usd', newProduct.price_usd);
    data.append('product_group', newProduct.product_group);
    data.append('hs_code', newProduct.hs_code);
    data.append('product_type', newProduct.product_type);
    data.append('origin_product', newProduct.origin_product);
    data.append('foto', newProduct.foto);
    data.append('caption', newProduct.caption);
    data.append('description', newProduct.description);
    data.append('product_type_id', newProduct.product_type_id);
    data.append('product_brand_id', newProduct.product_brand_id);
    data.append('industries', JSON.stringify(newProduct.industries));

    var config = {
      method: 'post',
      url: 'https://advantex.uz/product/insert_product_info',
      data: data
    };

    return axios(config)
  }

  updateProduct(product) {
    var data = new FormData();
    data.append('product_name', product.product_name);
    data.append('product_code', product.product_code);
    data.append('product_width', product.product_width);
    data.append('product_length', product.product_length);
    data.append('product_thickness', product.product_thickness);
    data.append('product_cost', product.product_cost);
    data.append('product_diameter', product.product_diameter);
    data.append('product_profile', product.product_profile);
    data.append('price_sum', product.price_sum);
    data.append('price_usd', product.price_usd);
    data.append('product_group', product.product_group);
    data.append('hs_code', product.hs_code);
    data.append('product_type', product.product_type);
    data.append('origin_product', product.origin_product);
    data.append('foto', product.foto);
    data.append('description', product.description);
    data.append('caption', product.caption);
    data.append('product_type_id', product.product_type_id);
    data.append('product_brand_id', product.product_brand_id);
    data.append('industries', JSON.stringify(product.industries));
    data.append('id', product.product_id);

    var config = {
      method: 'post',
      url: 'https://advantex.uz/product/update_product_info',
      headers: {
      },
      data: data
    };

    return axios(config)
  }

  deleteProduct(product) {
    var data = JSON.stringify({
      "id": product.product_id
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/product/deleteProduct',
      headers: {
        'Content-Type': 'application/json'
        // 'Cookie': 'PHPSESSID=f09f4455047320da7054861ac02c66edb9404039'
      },
      data: data
    };

    return axios(config)
  }

  getReserveAll() {
    return axios.get('https://advantex.uz/product/getReserveList').then(res => res.data);
  }

  getCurrencyAll() {
    return axios.get('https://advantex.uz/product/get_currency')
  }

  insertCurrency(currency) {
    var data = JSON.stringify({
      "usd": currency.usd,
      "eur": currency.eur,
      "sum": currency.sum
    });

    var config = {
      method: 'post',
      url: 'https://advantex.uz/product/insert_currency',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    return axios(config)
  }
}  
